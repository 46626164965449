/* Below animations are fore modal created using React-Modal */
html,
body {
    margin: 0;
    overflow-y: auto;
    overflow-x: hidden;
}

.ReactModal__Overlay {
    transition: transform 300ms ease-in-out;
    transition-delay: 100ms;
    transform: scale(0);
    /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open {
    transform: scale(1);
    /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close {
    transform: scale(0);
    /* transform: translateY(-150%); */
}

.CanvasView {
    position: absolute;
    height: 778px;
    width: 4096px;
    top: 0%;
    left: 50%;
    transform: translate(-40%, 0%);
    z-index: 0;
    background-color: #303035;
}

.Gray {
    position: absolute;
    height: 778px;
    width: 100%;
    z-index: -1;
    background-color: #303035;
}

/* conditional styling for 3D on mobile device */
@media only screen and (max-width:1023px) {
    .Gray {
        position: absolute;
        height: 600px;
        width: 100%;
        z-index: -1;
        background-color: #303035;
    }

    .CanvasView {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0%);
        height: 540px;
        width: 100%;
        top: 340px;
        touch-action: none;
    }

    .ScrollBlockTop {
        position: absolute;
        top: 240px;
        /* height: 180px; */
        height: 120px;
        width: 100%;
        z-index: 1
    }

    .ScrollBlockBottom {
        position: absolute;
        top: 760px;
        height: 120px;
        width: 100%;
        z-index: 1
    }

    .ScrollBlockRight {
        position: absolute;
        right: 0px;
        top: 360px;
        width: 33%;
        height: 400px;
        z-index: 1;

    }

    .ScrollBlockLeft {
        position: absolute;
        left: 0px;
        top: 360px;
        width: 33%;
        height: 400px;
        z-index: 1;

    }
}

html {
    scroll-behavior: smooth;
}